import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import Hamburger from '../../../icons/Hamburger.svg'
import HamburgerYachting from '../../../icons/yachting/Hamburger.svg'
import HamburgerXplorer from '../../../icons/xplorer/Hamburger.svg'
import HamburgerYachtSupport from '../../../icons/yachtsupport/Hamburger.svg'
import { currentSite, Site } from '../../../sites'

const HamburgerIcon = styled(Hamburger)`
  width: 32px;

  @media (prefers-reduced-motion: no-preference) {
    .line {
      opacity: 0;
      will-change: opacity;
      animation: ${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `} 800ms ease-in-out forwards;

      :nth-of-type(2) {
        animation-delay: 60ms;
      }
      :nth-of-type(3) {
        animation-delay: 120ms;
      }
    }
  }
`

const HamburgerIconYachting = styled(HamburgerYachting)`
  width: 32px;

  @media (prefers-reduced-motion: no-preference) {
    .line {
      opacity: 0;
      will-change: opacity;
      animation: ${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `} 800ms ease-in-out forwards;

      :nth-of-type(2) {
        animation-delay: 60ms;
      }
      :nth-of-type(3) {
        animation-delay: 120ms;
      }
    }
  }
`

const HamburgerIconXplorer = styled(HamburgerXplorer)(
  ({ theme }) => css`
    width: 40px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 48px;
    }

    @media (prefers-reduced-motion: no-preference) {
      .line {
        opacity: 0;
        will-change: opacity;
        animation: ${keyframes`
          from {
            opacity: 0;
          }
        to {
        opacity: 1;
      }
      `} 800ms ease-in-out forwards;

        :nth-of-type(2) {
          animation-delay: 60ms;
        }
        :nth-of-type(3) {
          animation-delay: 120ms;
        }
      }
    }
  `,
)

const HamburgerIconYachtSupport = styled(HamburgerYachtSupport)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    padding: ${theme.spacing.x1}px;
    border: 1px solid ${theme.colors.yachtSupport.grey03};
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 56px;
      height: 56px;
      padding: ${theme.spacing.x2}px;
    }

    @media (prefers-reduced-motion: no-preference) {
      .line {
        opacity: 0;
        will-change: opacity;
        animation: ${keyframes`
          from {
            opacity: 0;
          }
        to {
        opacity: 1;
      }
      `} 800ms ease-in-out forwards;

        :nth-of-type(2) {
          animation-delay: 60ms;
        }
        :nth-of-type(3) {
          animation-delay: 120ms;
        }
      }
    }

    :hover {
      color: ${theme.colors.yachtSupport.white};
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      border: 1px solid
        ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
)

export const StyledHamburgerIcon = () => {
  switch (currentSite) {
    case Site.Yachting:
      return <HamburgerIconYachting aria-label="Hamburger" />
    case Site.Xplorer:
      return <HamburgerIconXplorer aria-label="Hamburger" />
    case Site.YachtSupport:
      return <HamburgerIconYachtSupport aria-label="Hamburger" />
    default:
      return <HamburgerIcon aria-label="Hamburger" />
  }
}
