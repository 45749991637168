import { ComponentProps, ReactNode } from 'react'
import { Interpolation, Theme } from '@emotion/react'
import { currentSite, Site } from '../../sites'
import Link from '../Link'
import { YachtingButtonVariant } from '../Button'
import LinkButtonYachting from './LinkButton.yachting'
import LinkButtonXplorer from './LinkButton.xplorer'
import LinkButtonYachtSupport from './LinkButton.yachtsupport'

export interface LinkButtonProps extends ComponentProps<typeof Link> {
  variant: YachtingButtonVariant
  children: ReactNode
  // Needed for Storybook
  css?: Interpolation<Theme>
}

const LinkButton = (props: LinkButtonProps) => {
  switch (currentSite) {
    case Site.Yachting:
      return <LinkButtonYachting {...props} />
    case Site.Xplorer:
      return <LinkButtonXplorer {...props} />
    case Site.YachtSupport:
      return <LinkButtonYachtSupport {...props} />
    default:
      return null
  }
}

export default LinkButton
