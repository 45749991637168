import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
  isThemeAmelsLandingspage,
} from '../../../themes'
import { Props } from '../NavigationBar'

export const Container = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'light' | 'dark' }>(({ theme, variant }) => [
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    color: ${theme.colors.amels.silk};
    text-transform: uppercase;
    letter-spacing: 3.43px;
    font-weight: 500;
    padding: ${theme.spacing.x3}px ${theme.spacing.x4}px;
    display: flex;
    justify-content: space-evenly;
    user-select: none;

    ${isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.white};
      background-color: ${theme.colors.xplorer.primaryXPLavaBlack};
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x3}px ${theme.spacing.x10}px;
      }
    `};

    ${isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.white};
      text-transform: none;
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px ${theme.spacing.x7}px;
    }

    ${isSiteYachtSupport(theme) &&
    css`
      background: var(
        --Blur,
        linear-gradient(
          180deg,
          rgba(34, 41, 44, 0.8) 0%,
          rgba(34, 41, 44, 0) 100%
        )
      );
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x3}px ${theme.spacing.x4}px;
      }
    `};
  `,
  isSiteAmels(theme) &&
    css`
      font-size: 12px;
    `,
  isThemeAmelsLandingspage(theme) &&
    css`
      font-size: 12px;
    `,
  variant === 'dark' &&
    !isSiteXplorer(theme) &&
    !isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};

      ${isSiteYachting(theme) &&
      css`
        color: ${theme.colors.yachting.darkBlue};
      `};
    `,
])

export const Spacer = styled.div(
  ({ theme }) => css`
    flex: 1;

    ${isSiteYachtSupport(theme) &&
    css`
      flex: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex: 1;
      }
    `}
  `,
)

export const LogoContainer = styled.div<Pick<Props, 'variant'>>(
  ({ theme, variant }) => css`
    justify-content: center;

    ${isSiteXplorer(theme) &&
    css`
      align-items: center;
      display: flex;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: 6px ${theme.spacing.x3}px 0;
      }
    `}

    ${isSiteXplorer(theme) &&
    variant === 'dark' &&
    css`
      color: ${theme.colors.xplorer.white};
    `}

    ${isSiteYachting(theme) &&
    variant === 'dark' &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `};

    a {
      color: inherit;
    }

    ${isSiteYachtSupport(theme) &&
    css`
      align-items: center;
      display: flex;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: 6px ${theme.spacing.x3}px 0;
      }
    `}
  `,
)

export const NavigationContainer = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    justify-content: flex-end;

    ${isSiteAmels(theme) &&
    css`
      align-items: center;
    `}
  `,
)
