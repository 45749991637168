import { defaultTheme } from '../theme'
import fonts from './fonts'
import * as text from './text'

export const womenInYachtingPageTheme = {
  ...defaultTheme,
  text,
  fonts,
  site: {
    name: 'yachting-womenInYachtingLandingspage',
  },
} as const

export type WomenInYachtingPageTheme = typeof womenInYachtingPageTheme
