import { ComponentProps, forwardRef, MouseEvent, ReactNode } from 'react'

import { currentSite, Site } from '../../sites'
import Slant from '../../icons/yachting/Slant.svg'
import { StyledLogo } from './components/Logo'
import { StyledHamburgerIcon } from './components/HamburgerIcon'
import {
  DesktopMenuItem,
  HamburgerLink,
  HamburgerMenuItem,
  Menu,
  MenuLink,
  TabletMenuItem,
} from './components/Menu'
import {
  Container,
  LogoContainer,
  NavigationContainer,
  Spacer,
} from './components/Containers'
import {
  HamburgerTitle,
  HamburgerTitleContent,
} from './components/HamburgerTitle'

export interface Props
  extends Omit<ComponentProps<typeof Container>, 'children'> {
  variant: 'dark' | 'light'
  menuItems: Array<{
    key: string
    id?: string
    title: ReactNode
    href: string
  }>
  fullLogo?: boolean
  menuTitle?: string
  hasTopBar?: boolean
}

// TODO: Implement routing for the portfolio menu
const preventClick = (e: MouseEvent<HTMLElement>) => {
  e.preventDefault()
}

// eslint-disable-next-line react/display-name
const NavigationBar = forwardRef<HTMLDivElement, Props>(
  ({ variant, menuItems, fullLogo, menuTitle, ...others }: Props, ref) => {
    const slicedMenuItems =
      currentSite === Site.Xplorer ? menuItems.slice(0, 3) : menuItems

    return (
      <Container
        data-testid="navigationBar"
        variant={variant}
        ref={ref}
        {...others}
      >
        {menuItems.length > 0 && currentSite !== Site.Yachting && <Spacer />}
        <LogoContainer variant={variant}>
          <StyledLogo fullLogo={fullLogo} />
        </LogoContainer>
        {menuItems.length > 0 && (
          <NavigationContainer>
            <Menu>
              {currentSite !== Site.Yachting &&
                slicedMenuItems.slice(0, 3).map(({ key, id, title, href }) => {
                  const Component =
                    id === 'portfolioMenu' ? TabletMenuItem : DesktopMenuItem

                  return (
                    <Component key={key}>
                      <MenuLink
                        href={href}
                        onClick={
                          id === 'portfolioMenu' ? preventClick : undefined
                        }
                        data-testid={`navigationBar.item.${id || 'other'}`}
                      >
                        {title}
                      </MenuLink>
                    </Component>
                  )
                })}
              <HamburgerMenuItem>
                <HamburgerLink href="#menu">
                  {menuTitle &&
                    currentSite !== Site.Xplorer &&
                    currentSite !== Site.YachtSupport && (
                      <HamburgerTitle>
                        <HamburgerTitleContent>
                          {menuTitle}
                        </HamburgerTitleContent>
                        <Slant />
                      </HamburgerTitle>
                    )}
                  <StyledHamburgerIcon />
                </HamburgerLink>
              </HamburgerMenuItem>
            </Menu>
          </NavigationContainer>
        )}
      </Container>
    )
  },
)

export default NavigationBar
