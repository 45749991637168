import styled from '@emotion/styled'
import { SVGProps } from 'react'

import { currentSite, Site } from '../sites'
import LogoAmels from '../icons/amels/LogoAmels.svg'
import LogoAmelsEst from '../icons/amels/LogoAmelsEst.svg'
import LogoAmelsMobile from '../icons/amels/LogoAmelsMobile.svg'
import LogoYachting from '../icons/yachting/LogoYachting.svg'
import LogoXplorer from '../icons/xplorer/LogoXplorer.svg'
import LogoYachtSupport from '../icons/yachtsupport/LogoYachtSupport.svg'
import { hiddenClassName, textAnimationUp } from './AnimateText'

const AnimatedLogoAmelsEst = styled(LogoAmelsEst)`
  & {
    overflow: visible;

    .est,
    .est-date {
      opacity: 0;
      will-change: opacity;
      transform: translateY(1em);
      :not(.${hiddenClassName} &) {
        animation: ${textAnimationUp};
        animation-duration: 800ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 400ms;
      }
    }
    .est-date {
      :not(.${hiddenClassName} &) {
        animation-delay: 520ms;
      }
    }
  }
`

interface Props extends SVGProps<SVGElement> {
  site?: Site
}

const Full = ({ site = currentSite, ...others }: Props) => {
  switch (site) {
    case Site.Amels:
      return <AnimatedLogoAmelsEst aria-label="Amels" {...others} />
    case Site.Yachting:
      return <LogoYachting aria-label="Yachting" {...others} />
    case Site.Xplorer:
      return <LogoXplorer aria-label="Xplorer" {...others} />
    case Site.YachtSupport:
      return <LogoYachtSupport aria-label="YachtSupport" {...others} />
    default:
      return null
  }
}

const Large = ({ site = currentSite, ...others }: Props) => {
  switch (site) {
    case Site.Amels:
      return <LogoAmels aria-label="Amels" {...others} />
    case Site.Yachting:
      return <LogoYachting aria-label="Yachting" {...others} />
    case Site.Xplorer:
      return <LogoXplorer aria-label="Xplorer" {...others} />
    case Site.YachtSupport:
      return <LogoYachtSupport aria-label="YachtSupport" {...others} />
    default:
      return null
  }
}

const Simple = ({ site = currentSite, ...others }: Props) => {
  switch (site) {
    case Site.Amels:
      return <LogoAmelsMobile aria-label="Amels" {...others} />
    case Site.Yachting:
      return <LogoYachting aria-label="Yachting" {...others} />
    case Site.Xplorer:
      return <LogoXplorer aria-label="Xplorer" {...others} />
    case Site.YachtSupport:
      return <LogoYachtSupport aria-label="YachtSupport" {...others} />
    default:
      return null
  }
}

const Logo = {
  Full,
  Large,
  Simple,
}

export default Logo
