import { ButtonHTMLAttributes, ReactNode } from 'react'
import { Interpolation, Theme } from '@emotion/react'
import { currentSite, Site } from '../../sites'
import ButtonAmels from './Button.amels'
import ButtonYachting, { YachtingButtonVariant } from './Button.yachting'
import ButtonXplorer from './Button.xplorer'
import ButtonYachtSupport from './Button.yachtsupport'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: YachtingButtonVariant
  children: ReactNode
  // Needed for Storybook
  css?: Interpolation<Theme>
}

const Button = ({ variant, ...others }: ButtonProps) => {
  switch (currentSite) {
    case Site.Yachting:
      return <ButtonYachting {...others} variant={variant} />
    case Site.Xplorer:
      return <ButtonXplorer {...others} />
    case Site.Amels:
      return <ButtonAmels {...others} />
    case Site.YachtSupport:
      return <ButtonYachtSupport {...others} />
    default:
      return <ButtonAmels {...others} />
  }
}

export default Button

// TODO: Refactor this
export * from './Button.amels'
export * from './Button.yachting'
export * from './Button.xplorer'
export * from './Button.yachtsupport'
