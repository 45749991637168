import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../../../themes'
import Link from '../../Link'

export const Menu = styled.menu(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: ${theme.spacing.x4}px;

    ${isSiteXplorer(theme) &&
    css`
      align-items: center;
      gap: ${theme.spacing.x2}px;
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      align-items: center;
      gap: ${theme.spacing.x2}px;
    `}

    ${isSiteYachting(theme) &&
    css`
      margin-top: 12px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-top: 52px;
      }
    `}

    ${isSiteYachting(theme) &&
    css`
      margin-top: 28px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-top: 39px;
      }
    `};
  `,
)

export const MenuItem = styled.li``

export const TabletMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: list-item;
    }
  `,
)

export const DesktopMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: list-item;
    }
  `,
)

export const HamburgerMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      display: list-item;
    `}
  `,
)

export const MenuLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
    transition: opacity 200ms;

    ::after {
      content: '';
      height: 1px;
      background: ${theme.colors.amels.silk};
      display: block;
      transform: scaleX(0%);
      transform-origin: left;
      transition: transform ease-out 300ms;
    }

    :hover,
    :active {
      text-decoration: none;
      opacity: 1;
    }
    :active {
      ::after {
        transform: scaleX(100%);
      }
    }

    ${isSiteYachting(theme) &&
    css`
      font-weight: 700;
      padding: 5px 0;
      opacity: 1;

      ::after {
        position: relative;
        top: 5px;
        transform-origin: center;
        background: ${theme.colors.yachting.orange};
      }
      :hover::after {
        transform: scaleX(70%);
      }
    `}

    ${isSiteXplorer(theme) &&
    css`
      align-items: center;
      display: flex;
      font-size: 16px;
      text-transform: none;
      padding: ${theme.spacing.x1}px;
      color: ${theme.colors.xplorer.white};
      opacity: 1;

      :hover {
        opacity: 0.6;
      }
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      align-items: center;
      display: flex;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      padding: ${theme.spacing.x1}px;
      opacity: 1;
      transition: opacity 200ms;
      letter-spacing: 1.5px;

      ::after {
        content: '';
        height: 1px;
        background: ${theme.colors.yachtSupport.primaryYSOceanBlue};
        display: block;
        transform: scaleX(0%);
        transform-origin: left;
        transition: transform ease-out 300ms;
      }

      :hover {
        opacity: 0.6;
      }
    `}
  `,
)

export const HamburgerLink = styled(Link)(
  ({ theme }) => css`
    border: 0;
    // Increase touchable area (mostly for mobile)
    padding: 16px;
    margin: -16px;
    color: inherit;
    text-decoration: none;

    ${isSiteYachting(theme) &&
    css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;
    `}

    :hover {
      text-decoration: none;
    }
  `,
)
