import { css, keyframes, Theme } from '@emotion/react'
import { hiddenClassName } from '../AnimateText'
import type { ButtonProps } from './index'

export const primaryButtonCss = (theme: Theme) => css`
  --foreground: ${theme.colors.amels.body};
  --background: ${theme.colors.amels.deepBayAqua};

  display: inline-block;
  border-radius: 28px;
  border: solid 1px currentColor;
  color: var(--foreground);
  background: var(--background);
  padding: 6px ${theme.spacing.x4}px 3px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3.5px;
  font-weight: 600;
  position: relative;
  z-index: 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 28px;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    color: var(--background);
  }
`
export const primaryOutlineButtonCss = (theme: Theme) => css`
  --foreground: ${theme.colors.amels.deepBayAqua};
  --background: transparent;
  --hover-color: ${theme.colors.amels.silk};

  display: inline-block;
  border-radius: 28px;
  border: solid 1px var(--foreground);
  color: var(--foreground);
  background: var(--background);
  padding: 6px ${theme.spacing.x4}px 3px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3.5px;
  font-weight: 600;
  position: relative;
  z-index: 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 28px;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    color: var(--hover-color);
  }
`
export const secondaryOutlineButtonCss = (theme: Theme) => css`
  ${primaryOutlineButtonCss(theme)}
  --foreground: ${theme.colors.amels.bayGold};
`
export const whiteOutlineButtonCss = (theme: Theme) => css`
  ${primaryOutlineButtonCss(theme)}
  --foreground: ${theme.colors.amels.silk};
  --hover-color: ${theme.colors.amels.deepBayAqua};
  border-color: ${theme.colors.amels.silk};
`
export const invisibleButtonCss = () => css`
  border: 0;
  padding: 0;
`
export const activeTabButtonCss = (_theme: Theme) => css`
  opacity: 1;

  :not(.${hiddenClassName} &) {
    ::after {
      transform: translateX(-50%) scaleX(1);
    }
  }
`
export const tabButtonCss = (theme: Theme) => css`
  border: 0;
  padding: ${theme.spacing.x1}px;
  font-size: 12px;
  letter-spacing: 3.43px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 80px;
  color: ${theme.colors.amels.beach};
  position: relative;
  opacity: 0.5;
  transition: opacity 400ms ease-out;

  ::after {
    content: '';
    width: 80px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transition: transform 400ms ease-out;
    height: 2px;
    background: currentColor;
  }

  :hover,
  :active,
  :focus {
    ${activeTabButtonCss(theme)}
  }
`

const Button = ({
  children,
  type,
  ...others
}: Omit<ButtonProps, 'variant'>) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type || 'button'} {...others}>
    {children}
  </button>
)

export default Button
