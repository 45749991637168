import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  isSiteAmels,
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../../../themes'

export const HamburgerTitle = styled.div(
  ({ theme }) => css`
    ${isSiteAmels(theme) &&
    css`
      display: none;
    `}

    ${(isSiteYachting(theme) ||
      isThemeYachtingWomenInYachtingLandingspage(theme)) &&
    css`
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;
      text-transform: uppercase;

      svg {
        height: 35px;
      }
    `}
  `,
)

export const HamburgerTitleContent = styled.span(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      display: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: block;
      }
    `}
  `,
)
