import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

export const textAnimationUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(1em);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`
const textAnimationDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-1em);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`
export const textAnimationStatic = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const hiddenClassName = 'hidden'
/* eslint-disable no-nested-ternary */
const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'delay' && prop !== 'direction',
})<{ delay?: number; direction: 'up' | 'down' | 'static' }>(
  ({ delay, direction }) => [
    css`
      @media (prefers-reduced-motion: no-preference) {
        opacity: 0;
        will-change: opacity;
        transform: translateY(
          ${direction === 'up' ? 1 : direction === 'down' ? -1 : 0}em
        );
        :not(.${hiddenClassName} &) {
          animation: ${direction === 'up'
            ? textAnimationUp
            : direction === 'down'
            ? textAnimationDown
            : textAnimationStatic};
          animation-duration: 800ms;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-delay: var(--animation-delay);
        }
      }
    `,
    delay &&
      css`
        --animation-delay: ${delay || 0}ms;
      `,
  ],
)

interface Props
  extends Omit<ComponentProps<typeof Container>, 'direction' | 'delay'> {
  children: ReactNode
  direction?: 'up' | 'down' | 'static'
  delay?: number
}

const AnimateText = ({
  children,
  delay,
  direction = 'up',
  ...others
}: Props) => (
  <Container delay={delay} direction={direction} {...others}>
    {children}
  </Container>
)

export default AnimateText
