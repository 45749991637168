import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ArrowSlant from '../../icons/yachting/ArrowSlant.svg'
import Link from '../Link'
import { isSiteYachting } from '../../themes'
import type { LinkButtonProps } from './index'
import { YachtingButtonVariant, buttonCssYachting } from '../Button'

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: YachtingButtonVariant }>(
  ({ theme, variant }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${buttonCssYachting(theme, variant)};
    `}
  `,
)

const LinkButton = ({ children, ...others }: LinkButtonProps) => (
  <StyledLink {...others}>
    {children} <ArrowSlant />
  </StyledLink>
)

export default LinkButton
