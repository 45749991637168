import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Link from '../Link'
import { isSiteXplorer } from '../../themes'
import type { LinkButtonProps } from './index'
import { secondaryButtonCssXplorer } from '../Button'

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(
  ({ theme }) => css`
    ${isSiteXplorer(theme) &&
    css`
      ${secondaryButtonCssXplorer(theme)};
    `}
  `,
)

const LinkButton = ({ children, ...others }: LinkButtonProps) => (
  <StyledLink {...others}>{children}</StyledLink>
)

export default LinkButton
