import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import type { ButtonProps } from './index'
import { isSiteXplorer, XplorerTheme } from '../../themes'
import { body } from '../../themes/xplorer/text'

export const primaryButtonCssXplorer = (theme: Theme) => css`
  --foreground: ${theme.colors.xplorer.white};
  --background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border-radius: 24px;
  border: none;
  color: var(--foreground);
  background: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  width: 100%;
  text-transform: none;
  position: relative;
  z-index: 0;
  align-self: flex-start;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 28px;
    background: transparent;
  }
  :hover {
    ::before {
      background: ${theme.colors.xplorer.primaryXPLavaBlack};
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    cursor: pointer;
  }
`
export const secondaryButtonCssXplorer = (theme: Theme) => css`
  --foreground: ${theme.colors.xplorer.primaryXPLavaBlack};
  --background: transparent;
  --hover-color: ${theme.colors.xplorer.white};

  ${body(theme)}
  font-weight: 600;
  padding: 12px ${theme.spacing.x3}px;
  border-radius: 24px;
  background-color: var(--background);
  border: 2px solid ${theme.colors.xplorer.primaryXPLavaBlack};
  color: var(--foreground);
  position: relative;
  z-index: 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - ${theme.spacing.x2}px);
    height: calc(100% - ${theme.spacing.x2}px);
    z-index: -1;
    border-radius: 24px;
  }
  :hover {
    ::before {
      background: ${theme.colors.xplorer.primaryXPLavaBlack};
      animation: ${keyframes`
        to {
          width: calc(100% + 4px);
          height: calc(100% + 4px);
        }
      `} 300ms ease-out forwards;
    }
    color: var(--hover-color);
    cursor: pointer;
    text-decoration: none;
  }
`

export const primaryOutlineButtonCssXplorer = (theme: Theme) => css`
  --foreground: ${theme.colors.xplorer.white};
  --background: transparent;
  --hover-color: ${theme.colors.xplorer.primaryXPLavaBlack};

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border-radius: 24px;
  border: solid 2px var(--foreground);
  color: var(--foreground);
  background: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  position: relative;
  z-index: 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 24px;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: calc(100% + 4px);
          height: calc(100% + 4px);
        }
      `} 300ms ease-out forwards;
    }
    text-decoration: none;
    color: var(--hover-color);
    cursor: pointer;
  }
`
export const secondaryOutlineButtonHoverStyleCssXplorer = (theme: Theme) => css`
  --hover-color: ${theme.colors.xplorer.primaryXPLavaBlack};

  ::before {
    background: var(--hover-color);
    background-color: var(--hover-color);
    animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
  }
  color: ${theme.colors.xplorer.white};
  cursor: pointer;
`

export const secondaryOutlineButtonCssXplorer = (theme: Theme) => css`
  --foreground: ${theme.colors.xplorer.primaryXPLavaBlack};
  --background: transparent;

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border-radius: 24px;
  border: solid 2px var(--foreground);
  color: var(--foreground);
  background: var(--background);
  background-color: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  position: relative;
  cursor: pointer;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // Include border width in width calculation
    width: calc(100% + 2px) !important;
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 20px;
    background: transparent;
  }

  :hover {
    ${secondaryOutlineButtonHoverStyleCssXplorer(theme)}
  }
`

export const secondaryOutlineInvertedButtonHoverStyleCssXplorer = (
  theme: Theme,
) => css`
  --hover-color: ${theme.colors.xplorer.white};

  ::before {
    background: var(--hover-color);
    background-color: var(--hover-color);
    animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
  }
  color: ${theme.colors.xplorer.primaryXPLavaBlack};
  cursor: pointer;
`

export const secondaryOutlineInvertedButtonCssXplorer = (theme: Theme) => css`
  --foreground: ${theme.colors.xplorer.white};
  --background: transparent;

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border-radius: 24px;
  border: solid 2px var(--foreground);
  color: var(--foreground);
  background: var(--background);
  background-color: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  position: relative;
  cursor: pointer;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // Include border width in width calculation
    width: calc(100% + 2px) !important;
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 20px;
    background: transparent;
  }

  :hover {
    ${secondaryOutlineInvertedButtonHoverStyleCssXplorer(theme)}
  }
`

export const invisibleButtonXplorerCss = () => css`
  border: 0;
  padding: 0;
  background-color: transparent;
`

export const activeTabCssXplorer = (
  theme: Theme,
  secondary?: 'secondary',
) => css`
  background: ${secondary
    ? theme.colors.xplorer.primaryXPDarkOceanGreen
    : theme.colors.xplorer.secondaryDamenYachtingOrange};
  color: ${theme.colors.xplorer.white};
  outline: transparent;

  ::before {
    content: '';
    background: ${secondary
      ? theme.colors.xplorer.primaryXPDarkOceanGreen
      : theme.colors.xplorer.secondaryDamenYachtingOrange};
  }
`

export const tabCssXplorer = (theme: Theme, secondary?: 'secondary') => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(${theme.spacing.x1}px / 2) ${theme.spacing.x2}px;
  background: ${secondary
    ? theme.colors.xplorer.white
    : theme.colors.xplorer.xpBronze10};
  border-radius: 100px;
  font-size: 16px;
  line-height: 120%;
  color: ${secondary
    ? theme.colors.xplorer.primaryXPDarkOceanGreen
    : theme.colors.xplorer.secondaryDamenYachtingOrange};
  text-transform: uppercase;
  transition: all 400ms ease-out;
  width: fit-content;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    padding: ${theme.spacing.x1}px ${theme.spacing.x3}px;
  }

  :hover,
  :active,
  :focus {
    ${activeTabCssXplorer(theme, secondary)}
  }
`

export const buttonCssXplorer = (
  theme: XplorerTheme,
  variant?: 'primary' | 'secondary',
) => [
  (!variant || variant === 'primary') && primaryButtonCssXplorer(theme),
  variant === 'secondary' && secondaryButtonCssXplorer(theme),
]
const StyledButton = styled('button')<ButtonProps>(
  ({ theme, variant }) =>
    isSiteXplorer(theme) &&
    css`
      ${buttonCssXplorer(theme, variant)};
    `,
)

const Button = ({
  children,
  type,
  ...others
}: Omit<ButtonProps, 'variant'>) => (
  // eslint-disable-next-line react/button-has-type
  <StyledButton type={type || 'button'} {...others}>
    {children}
  </StyledButton>
)

export default Button
