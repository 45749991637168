import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../../../themes'
import Logo from '../../Logo'
import Link from '../../Link'
import AnimateText from '../../AnimateText'

const StyledLogoSmall = styled(Logo.Simple)(
  ({ theme }) => css`
    width: 43px;

    ${isSiteYachting(theme) &&
    css`
      width: 120px;
    `};

    ${isSiteXplorer(theme) &&
    css`
      width: 172px;
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      width: 212px;
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

const StyledLogoLarge = styled(Logo.Large)(
  ({ theme }) => css`
    width: 191px;

    ${isSiteYachtSupport(theme) &&
    css`
      width: 320px;
    `};

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: list-item;
    }
  `,
)

const StyledLogoFull = styled(Logo.Full)(
  ({ theme }) => css`
    width: 191px;

    ${isSiteXplorer(theme) &&
    css`
      width: 172px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 214px;
      }
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      width: 212px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 320px;
      }
    `};

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: list-item;
    }
  `,
)

export const StyledLogo = ({ fullLogo }: { fullLogo?: boolean }) => (
  <AnimateText direction="down">
    <Link href="/">
      <StyledLogoSmall data-testid="logo" />
      {fullLogo ? (
        <StyledLogoFull data-testid="logo" />
      ) : (
        <StyledLogoLarge data-testid="logo" />
      )}
    </Link>
  </AnimateText>
)
