import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import ArrowSlant from '../../icons/yachting/ArrowSlant.svg'
import {
  isSiteYachting,
  YachtingTheme,
  WomenInYachtingPageTheme,
} from '../../themes'
import type { ButtonProps } from './index'
import { hiddenClassName } from '../AnimateText'

export type YachtingButtonVariant = 'primary' | 'secondary'

export const primaryButtonCssYachting = (theme: Theme) => css`
  --foreground: ${theme.colors.yachting.orange};
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  font-size: 20px;
  min-height: 55px;
  gap: ${theme.spacing.x2}px;
  color: var(--foreground);
  transition: 500ms ease-in, color 400ms ease-in;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 22px;
    min-height: 80px;
  }

  > svg {
    transition: transform 300ms ease-in;
    height: 39px;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: 51px;
    }
  }

  :not(:disabled) {
    cursor: pointer;
  }

  :hover {
    text-decoration: none;

    > svg {
      transform: translateX(4px);
    }
  }
`
export const mediumButtonCssYachting = (theme: Theme) => css`
  padding: 0;
  font-size: 20px;
  min-height: 55px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
    min-height: 55px;
  }

  > svg {
    height: 39px;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: 39px;
    }
  }
`
export const secondaryButtonCssYachting = (theme: Theme) => css`
  ${primaryButtonCssYachting(theme)}
  --foreground: ${theme.colors.yachting.white};
`

export const activeTabCssYachting = (_theme: Theme) => css`
  :not(.${hiddenClassName} &) {
    ::after {
      transform: translateX(-50%) scaleX(1);
    }
  }
`
export const tabCssYachting = (theme: Theme) => css`
  border: 0;
  padding: ${theme.spacing.x1}px;
  font-family: ${theme.fonts.Georgia.style.fontFamily};
  font-size: 18px;
  font-weight: 300;
  min-width: 80px;
  color: ${theme.colors.yachting.darkBlue};
  cursor: pointer;
  position: relative;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    padding: ${theme.spacing.x1}px ${theme.spacing.x1}px ${theme.spacing.x3}px;
  }

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
  }

  ::after {
    content: '';
    width: 80px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transition: transform 400ms ease-out;
    height: 2px;
    background: ${theme.colors.yachting.orange};
  }

  :hover,
  :active,
  :focus {
    ${activeTabCssYachting(theme)}
  }
`

export const buttonCssYachting = (
  theme: YachtingTheme | WomenInYachtingPageTheme,
  variant: YachtingButtonVariant,
) => [
  variant === 'primary' && primaryButtonCssYachting(theme),
  variant === 'secondary' && secondaryButtonCssYachting(theme),
]

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<ButtonProps>(
  ({ theme, variant }) =>
    isSiteYachting(theme) &&
    variant &&
    css`
      ${buttonCssYachting(theme, variant)};
    `,
)

const Button = ({ children, type, ...others }: ButtonProps) => (
  // eslint-disable-next-line react/button-has-type
  <StyledButton type={type || 'button'} {...others}>
    {children}
    {others.variant ? <ArrowSlant /> : null}
  </StyledButton>
)

export default Button
