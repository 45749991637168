import { defaultTheme } from '../theme'
import fonts from './fonts'
import * as text from './text'

export const landingspageTheme = {
  ...defaultTheme,
  text,
  fonts,
  site: {
    name: 'amels-landingspage',
  },
} as const

export type LandingspageTheme = typeof landingspageTheme
