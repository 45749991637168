import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import type { ButtonProps } from './index'
import { isSiteYachtSupport, YachtSupportTheme } from '../../themes'
import { body } from '../../themes/yachtsupport/text'

export const primaryButtonCssYachtSupport = (theme: Theme) => css`
  --foreground: ${theme.colors.yachtSupport.white};
  --background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border: solid 1px ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  color: var(--foreground);
  background: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  align-self: flex-start;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    color: var(--background);
    text-decoration: none;
    border: solid 1px var(--foreground);
    cursor: pointer;
  }
`

export const primaryOutlineButtonCssYachtSupport = (theme: Theme) => css`
  --foreground: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  --background: transparent;
  --hover-color: ${theme.colors.yachtSupport.white};

  ${body(theme)}
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  border: solid 1px ${theme.colors.yachtSupport.grey03};
  color: var(--foreground);
  background: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  position: relative;
  z-index: 0;
  width: fit-content;
  white-space: nowrap;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: calc(100% + 4px);
          height: calc(100% + 4px);
        }
      `} 300ms ease-out forwards;
    }
    color: var(--hover-color);
    text-decoration: none;
    cursor: pointer;
  }
`

export const secondaryButtonCssYachtSupport = (theme: Theme) => css`
  --foreground: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  --background: ${theme.colors.yachtSupport.white};

  ${body(theme)}
  font-weight: 600;
  border: solid 1px ${theme.colors.yachtSupport.grey03};
  color: var(--foreground);
  padding: 12px ${theme.spacing.x3}px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  align-self: flex-start;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    background: transparent;
  }
  :hover {
    ::before {
      background: var(--foreground);
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    color: var(--background);
    text-decoration: none;
    border: solid 1px var(--foreground);
    cursor: pointer;
  }
`

export const secondaryOutlineButtonCssYachtSupport = (theme: Theme) => css`
  --foreground: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
  --background: transparent;
  --hover-color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};

  ${body(theme)}
  font-weight: 600;
  display: inline-block;
  border-radius: 24px;
  border: solid 2px var(--foreground);
  color: var(--foreground);
  background: var(--background);
  padding: 12px ${theme.spacing.x3}px;
  position: relative;
  cursor: pointer;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
    border-radius: 24px;
    background: transparent;
  }
  :hover {
    ::before {
      background: ${theme.colors.yachtSupport.grey01};
      animation: ${keyframes`
        to {
          width: 100%;
          height: 100%;
        }
      `} 300ms ease-out forwards;
    }
    color: ${theme.colors.yachtSupport.ysCarbonBlack70Solid};
    cursor: pointer;
  }
`

export const invisibleButtonYachtSupportCss = () => css`
  border: 0;
  padding: 0;
  background-color: transparent;
`

export const activeTabCssYachtSupport = (
  theme: Theme,
  secondary?: 'secondary',
) => css`
  border: solid 1px ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  background: ${secondary
    ? theme.colors.yachtSupport.primaryYSOceanBlue
    : theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  color: ${theme.colors.yachtSupport.white};
  outline: transparent;

  ::before {
    content: '';
    background: ${secondary
      ? theme.colors.yachtSupport.primaryYSOceanBlue
      : theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  }
`

export const tabCssYachtSupport = (
  theme: Theme,
  secondary?: 'secondary',
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x1}px ${theme.spacing.x3}px;
  border: solid 1px ${theme.colors.yachtSupport.grey03};
  background: ${theme.colors.yachtSupport.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${secondary
    ? theme.colors.yachtSupport.primaryYSOceanBlue
    : theme.colors.yachtSupport.secondaryDamenYachtingOrange};
  text-transform: uppercase;
  transition: all 400ms ease-out;
  width: fit-content;

  :hover,
  :active,
  :focus {
    ${activeTabCssYachtSupport(theme, secondary)}
  }
`

export const buttonCssYachtSupport = (theme: YachtSupportTheme) =>
  primaryButtonCssYachtSupport(theme)

const StyledButton = styled('button')<ButtonProps>(
  ({ theme }) =>
    isSiteYachtSupport(theme) &&
    css`
      ${buttonCssYachtSupport(theme)};
    `,
)

const Button = ({
  children,
  type,
  ...others
}: Omit<ButtonProps, 'variant'>) => (
  // eslint-disable-next-line react/button-has-type
  <StyledButton type={type || 'button'} {...others}>
    {children}
  </StyledButton>
)

export default Button
